.container{
	max-width: 780px;
	width: 100%;
	margin: auto;
}

.mapShow {
	display: flex;
	justify-content: space-around;
}

.mapShow > img {
	width: 80%;
}

.mapContainer{
	margin: 30px 0px;
}

.infoMap {
	display: flex;
	flex-direction: column;
	justify-content: baseline;
	margin-right: 20px;
}
.roundColorOne, .roundColorTwo{
	border-radius: 100%;
	width: 15px;
	height: 15px;
	display: inline-block;
	margin: 0px 5px;
}

.roundColorOne{
	background: #293990;
}

.roundColorTwo{
	background: #AAB5E9;
}