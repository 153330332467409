.subTitleWarning {
	color: #293990;
	font-size: 14px;
	display: flex;
	align-items: center;
	margin-top: 3px;
}

.labelSelect {
	color: #7e8094;
	margin-bottom: 4px;
	margin-top: 2px;
	display: block;
	font-size: 14px;
}
