#virtual-assistant-root {
	top: 0;
	right: 0;
	position: fixed;
	height: 100%;
	width: 0px;
	border: 0px;
	margin: 0px;
	padding: 0px;
	z-index: 10000;
}

#virtual-assistant-root.active {
	width: 375px;
	z-index: 10000;
}

#virtual-assistant-toggle-button-container {
	top: 100px;
	left: auto;
	right: 0px;
	position: absolute;
	border: 0px;
	margin: 0px;
	padding: 0px;
}

#virtual-assistant-root,
#virtual-assistant-toggle-button-container {
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

#virtual-assistant-toggle-button-container.active {
	right: 375px;
}

/***Daza 09/09/2021***/
.activeDiv {
	width: 375px !important;
	z-index: 10000 !important;
}

.activeButton {
	right: 375px !important;
}

#otrosServiciosContenedor {
	top: 0;
	right: 0;
	position: fixed;
	height: 100%;
	width: 0px;
	border: 0px;
	margin: 0px;
	padding: 0px;
	z-index: 10000;
}

#otrosServiciosContenedor,
#widgetOtros {
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

/***Termina***/

#virtual-assistant-iframe-container {
	background-color: white;
	height: 100%;
	min-width: 375px;
	width: 375px;
	max-width: 375px;
	transition: all 0.5s;
}

#virtual-assistant-iframe {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: hidden;
	overflow-x: hidden;
}

#virtual-assistant-toggle-button.button {
	padding: 30px 5px 30px 5px;
	outline: none;
	border-radius: 15px 0px 0px 15px;
	background: #007fed;
	color: #fff;
	font-size: 24px;
	display: block;
	margin-left: -25px;
	transition: transform 0.25s;
	text-decoration: none;
}

#virtual-assistant-toggle-button.button:hover {
	text-decoration: none;
	background: #2198ff;
}
