body {
	margin: 0;
	font-family: 'Geomanist';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'OpenDyslexic';
	src: url('../src/assets/font/OpenDyslexic-Regular.eot');
	src: local('OpenDyslexic Regular'), local('OpenDyslexic-Regular'),
		url('../src/assets/font/OpenDyslexic-Regular.eot') format('embedded-opentype'),
		url('../src/assets/font/OpenDyslexic-Regular.woff2') format('woff2'),
		url('../src/assets/font/OpenDyslexic-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
.custom-cursor, .custom-cursor *{
	cursor: url('../src/assets/img/iconsToolAccessibility/cursor.svg'), auto !important;
}
.fontDislexic, .fontDislexic *, .fontDislexic .MuiMenuItem-root {
	font-family: 'OpenDyslexic' !important;
}
.grayScales {
	filter: grayscale(1) !important;
}
.activeContrast {
	filter: invert(1);
}
.bg-white{
	background-color: #F8F8F8;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	cursor: pointer;
}

/* DESKTOP CLASSES */
.grid-2-columns {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
}

.grid-3-columns {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
}

.grid-4-columns {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
}

/* MOBILE CLASSES */
@media (max-width: 600px) {
	.grid-2-columns {
		grid-template-columns: repeat(1, 1fr);
	}
	.grid-3-columns {
		grid-template-columns: repeat(1, 1fr);
	}
	.grid-4-columns {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (min-width: 900px) {
	@page {
		size: 400mm 500mm;
		/* size: 600mm 900mm; */
	}
}
