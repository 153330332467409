.container {
	text-align: center;
}
.container > header {
	box-shadow: 0px 3px 14px #00000014;
	padding: 15px 0px;
}
.container > main {
	padding: 10px 20px;
}
.title {
	color: #293990;
	font-weight: 500;
	font-size: 26px;
	margin: 0px 0px;
}
.subTitle {
	color: #333333;
	font-weight: 500;
	font-size: 20px;
}
.buttonActions {
	display: flex;
	justify-content: center;
	margin: 10px 0px;
}
.buttonActions > button {
	width: 220px;
	margin: 10px 10px;
}
.parrafoObligatorio {
	color: #293990;
	margin: 10px 0px;
	text-align: left;
	font-weight: 500;
}
@media only screen and (max-width: 900px) {
	.buttonActions {
		flex-direction: column-reverse;
	}
	.buttonActions > button {
		width: auto;
	}
}