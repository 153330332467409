.container {
	display: grid;
	grid-template-columns: 24px auto;
}

.asider {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	position: relative;
}

.asider > div {
	display: block;
    margin-top: 3px;
}

.asider > div > label {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	border: 1px solid #afb4cb;
	background: #f0f6ff;
	border-radius: 100%;
}

.asiderActive {
	background: rgba(203, 255, 224, 1) !important;
	border-color: rgba(0, 201, 81, 1) !important;
}

.dontPoint {
	background: #636363;
	border-radius: 100%;
	width: 5px;
	height: 5px;
}

.article {
	margin-left: 10px;
	margin-bottom: 10px;
}

.article > header {
	display: block;
	font-weight: 500;
	color: #999999;
	margin-top: 0px;
    margin-bottom: 10px;
}

.headerActive {
	color: #00c951 !important;
}

.article > div {
	color: #999999;
}

.contentActive {
	color: #000 !important;
}

.lineStepNot,
.lineStep {
	width: 1px;
	height: 100%;
}

.lineStep {
	background-image: linear-gradient(
		180deg,
		#ffffff 25%,
		#707070 25%,
		#707070 50%,
		#ffffff 50%,
		#ffffff 75%,
		#707070 75%,
		#707070 100%
	);
	background-size: 12px 12px;
}

.textLine {
	color: rgb(40, 57, 144);
	font-weight: 600;
	margin-top: -7px;
}

.iconoCheckActive {
	color: rgba(0, 201, 81, 1);
}

.iconoCheck {
	color: rgba(40, 57, 144, 0.5);
}

.stepSub { 
    margin-top: 15px;
}

.stepSub > header {
	display: block;
	font-weight: 500;
	color: #999999;
	margin-top: -4px;
}