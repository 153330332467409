.margenAbajo {
	margin-bottom: 10px;
}

.AviableCreditPanelBox1 {
	display: flex;
	justify-content: center;
}

.fueteFuerte {
	font-weight: 'bold';
}

.fuenteIzquierda {
	text-align: 'left';
}

.dividerClass {
	margin: '30px 0px';
}

.boxCustom {
	width: 250px;
	margin: 'auto';
}

.imgCustom {
	width: 150px;
}

.margenIzquierda {
	padding-left: 20px;
}
