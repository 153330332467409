/* ----------------- lista Principal --------------------- */
.listCard {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 10px;
	margin-top: 20px;
	padding: 0px 10px;
}

.listCard > article {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px 0px;
	margin: 0px 10px;
}

.listCard > article:last-child {
	border-bottom: 0px;
}

.listCard > article > div {
	font-size: 17px;
}

/* ---------------- Sublista ----------------------- */
.listCardSub {
	margin-top: 5px;
	display: grid;
	grid-template-columns: 1fr;
	padding: 5px 0px 0px 0px;
	grid-column: 1 / span 2;
}

.listCardSub > article {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 10px 0px;
	color: gray;
}

.listCardSub > article > div {
	font-size: 17px;
}

/*  --------------- item ------------------- */
.itemCard {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.itemCard > div {
	text-align: right;
}

@media (max-width: 900px) {
	.listCard > article,
	.listCardSub > article {
		grid-template-columns: 1fr;
	}

    .listCardSub {
        grid-column: 1;
    }
}